<template>
    <div class="pvhFactory">
        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:20px;">{{$t('User')}}</div>
            </div>
            <template>
                <avue-crud :option="tableOption"
                           :data="tableData"
                           :page="page"
                           ref="crud"
                           @current-change="currentChange"
                           @size-change="sizeChange" v-loading="loading">
                    <!--<template slot="status" slot-scope="scope">
        <el-switch v-model="scope.row.status" :inactive-value="1" :active-value="0"
        @change="change(scope.row)">
        </el-switch>
    </template>-->
                    <template slot="menuRight" style="width: calc(100% - 80px);">
                        <el-row :gutter="20" class="topFromSearch">
                            <el-col :xs="24" :sm="16" :md="16" :lg="19" :xl="20" class="fromOutDiv">
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span>{{$t('NAME OR NUMBER OF PERSONNEL')}}:</span>
                                    <el-input v-model="nameVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                </el-col>
                            </el-col>
                            <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4" style="padding-top: 15px; text-align: right; align-self: flex-end;">
                                <el-button type="primary" size="small" @click="search">{{$t('SEARCH')}}</el-button>
                            </el-col>
                        </el-row>
                    </template>
                    <template slot="menu" slot-scope="{row,index,size,type}">
                        <el-button icon="el-icon-data-analysis" :size="size" :type="type" @click="goPermission(row)">{{$t('Permissions to')}}</el-button>
                        <el-button icon="el-icon-data-analysis" :size="size" :type="type" @click="goRole(row)">{{$t('Roles to')}}</el-button>
                    </template>
                </avue-crud>
            </template>
        </basic-container>
    </div>
</template>
<script>
import {list} from "@/api/userList"

export default {
    data() {
        return {
            loading: true,
            nameVal: "",
            page: {
                pageSize: 20,
                pagerCount: 5,
                total: 10,
                pageCurrent: 1
            },
            disabled: false,
            tableData: [],
            tableOption: {
                page: true,
                delBtn: false,
                addBtn: false,
                editBtn: false,
                menuWidth: 200,
                refreshBtn: false,
                tip: false,
                columnBtn: false,
                emptyText: this.$t('No Data'),
                menuTitle: this.$t('OPERATION'),
                column: [
                    {
                        label: this.$t('NAME'),
                        prop: "name",
                        rules: [{
                            required: true,
                            message: this.$t('PLEASE ENTER A NAME'),
                            trigger: "blur"
                        }]
                    },
                    {
                        label: this.$t('CODE'),
                        prop: "code",
                        rules: [{
                            required: true,
                            message: this.$t('PLEASE ENTER THE CODE'),
                            trigger: "blur"
                        }]
                    },
                    {
                        label: this.$t('TELEPHONE'),
                        prop: "phoneNumber",
                        rules: [{
                            required: true,
                            message: this.$t('PLEASE ENTER PHONE NUMBER'),
                            trigger: "blur"
                        }]
                    },
                    {
                        label: this.$t('GENDER'),
                        value: 2,
                        type: "select",
                        prop: "gender",
                        dicData: [{
                            label: this.$t('MALE'),
                            value: 1
                        }, {
                            label: this.$t('FEMALE'),
                            value: 2
                        }],
                        rules: [{
                            required: true,
                            message: this.$t('PLEASE SELECT GENDER'),
                            trigger: "blur"
                        }]
                    }]
            }
        }
        },
        computed: {
            setData() {
                return {
                    tableOption: {
                        page: true,
                        delBtn: false,
                        menuWidth: 200,
                        refreshBtn: false,
                        tip: false,
                        columnBtn: false,
                        emptyText: this.$t('No Data'),
                        menuTitle: this.$t('OPERATION'),
                        column: [
                            {
                                label: this.$t('NAME'),
                                prop: "name",
                                rules: [{
                                    required: true,
                                    message: this.$t('PLEASE ENTER A NAME'),
                                    trigger: "blur"
                                }]
                            },
                            {
                                label: this.$t('CODE'),
                                prop: "code",
                                rules: [{
                                    required: true,
                                    message: this.$t('PLEASE ENTER THE CODE'),
                                    trigger: "blur"
                                }]
                            },
                            {
                                label: this.$t('TELEPHONE'),
                                prop: "phoneNumber",
                                rules: [{
                                    required: true,
                                    message: this.$t('PLEASE ENTER PHONE NUMBER'),
                                    trigger: "blur"
                                }]
                            },
                            {
                                label: this.$t('GENDER'),
                                value: 2,
                                type: "select",
                                prop: "gender",
                                dicData: [{
                                    label: this.$t('MALE'),
                                    value: 1
                                }, {
                                    label: this.$t('FEMALE'),
                                    value: 2
                                }],
                                rules: [{
                                    required: true,
                                    message: this.$t('PLEASE SELECT GENDER'),
                                    trigger: "blur"
                                }]
                            }]
                    }
                }
            }
        },
        created() {
            this.list();
        },
        methods: {
            focusDiv(obj) {//搜索框获取焦点
                if (obj.currentTarget === undefined) {
                    obj.$el.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                } else {
                    obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                }
            },//搜索框失去焦点
            blurDiv(obj) {
                if (obj.currentTarget === undefined) {
                    if (obj.value === "") {
                        obj.$el.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                    }
                } else {
                    if (obj.target.value == "") {
                        obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                    }
                }

            },//表格行样式
            rowStyle({ rowIndex }) {
                if (rowIndex % 2 === 0) {
                    return {
                        backgroundColor: '#F8F8F8'
                    }
                }
            },
        //权限编辑
            goPermission(item) {
                console.log(item)
                this.$router.push({ path: '/peop/userPermission', query: { userName: item.name, userId: item.userId } })
            },
            goRole(item) {
                console.log(item)
                this.$router.push({ path: '/peop/roleToUser', query: { userName: item.name, userId: item.userId } })
            },
      currentChange(pageCurrent) {
        this.page.pageCurrent = pageCurrent;
        this.list();
      },
      sizeChange(pageSize) {
        this.page.pageCurrent = 1;
        this.page.pageSize = pageSize;
        this.list();
      },
      search() {
        this.list();
      },
      list() {
          list(this.nameVal, this.page.pageCurrent, this.page.pageSize).then(res => {
              this.loading = false;
              this.tableData = res.data.corpUserss;
              this.page.total = res.data.totalCount;
              this.page.pageSize = res.data.PageSize;
      })
     }
  }
}
</script>
<style scoped>
    .el-select-dropdown .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        padding-left: 10px;
    }
</style>